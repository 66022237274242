export class DomEventable {
  private bindings: {[s: string]: any}[] = [];
  private boundHandler: {(event: Event): void};

  constructor() {
    this.boundHandler = this.handler.bind(this);
  }

  on(scope: string | HTMLElement | Window | Document, type: string, handler: {(...args: any[]): void}): void;
  on(scope: string | HTMLElement | Window | Document, type: string, target: string, handler: {(...args: any[]): void}): void;
  on(scope: string | HTMLElement | Window | Document, type: string, targetOrHandler: any, handler?: {(...args: any[]): void}): void {
    let target: string | null;

    [scope, type, target, handler] = this.prepareParameters(scope, type, targetOrHandler, handler);

    this.queryScopeElements(scope).forEach((element) => {
      this.bindings.push({
        type: type,
        scope: element,
        target: target,
        handler: handler,
      });

      element.addEventListener(type, this.boundHandler, false);
    });
  }

  off(scope: string | HTMLElement | Window | Document, type: string, handler?: {(...args: any[]): void}): void;
  off(scope: string | HTMLElement | Window | Document, type: string, target: string, handler?: {(...args: any[]): void}): void;
  off(scope: string | HTMLElement | Window | Document, type: string, targetOrHandler: any, handler?: {(...args: any[]): void}): void {
    let target: string | null;

    [scope, type, target, handler] = this.prepareParameters(scope, type, targetOrHandler, handler);

    this.queryScopeElements(scope).forEach((element) => {
      const matchingBindings = this.bindings.filter((binding) => {
        const matchesType: boolean = (type == binding.type);
        const matchesScope: boolean = (element == binding.scope);
        const matchesTarget: boolean = (target == binding.target);
        const matchesHandler: boolean = (handler == undefined || handler == binding.handler);

        return matchesType && matchesScope && matchesTarget && matchesHandler;
      });

      if (matchingBindings.length > 0) {
        matchingBindings.forEach((binding) => {
          this.bindings.splice(this.bindings.indexOf(binding), 1);
        });

        element.removeEventListener(type, this.boundHandler, false);
      }
    });
  }

  dispatch(type: string, target: string, parameters: any[] = []) {
    const event = new Event(type);

    Array.from(document.querySelectorAll(target)).forEach((element: HTMLElement) => {
      element.dispatchEvent(event);
    });
  }

  private handler(event: Event) {
    const eventType = event.type;
    const eventScope = event.currentTarget;
    const eventTarget = event.target as HTMLElement;
    const isEventTargetAnHTMLElement = (event.target instanceof HTMLElement);

    this.bindings.forEach((binding) => {
      const matchesType: boolean = (eventType == binding.type);
      const matchesScope: boolean = (eventScope == binding.scope);
      const matchesTarget: boolean = (eventTarget.closest(binding.target) != null || eventTarget.matches(binding.target));
      const hasIrrelevantTarget: boolean = (binding.target == null || !isEventTargetAnHTMLElement);

      if (matchesType && matchesScope && (matchesTarget || hasIrrelevantTarget)) {
        binding.handler(event);
      }
    });
  }

  private prepareParameters(scope: string | HTMLElement | Window | Document, type: string, targetOrHandler: any, handler?: {(...args: any[]): void}): any[] {
    let target: string | null;

    if (typeof targetOrHandler === 'function') {
      target = null;
      handler = targetOrHandler;
    } else {
      target = targetOrHandler;
    }

    return [scope, type, target, handler];
  }

  private queryScopeElements(scope: string | HTMLElement | Window | Document): (Window | Document | HTMLElement)[] {
    if (typeof scope === 'string') {
      return Array.from(document.querySelectorAll(scope)) as HTMLElement[];
    } else {
      return [scope];
    }
  }
}

export const DomEventApi = new DomEventable();
