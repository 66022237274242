class ModuleHTMLElement extends HTMLElement {
  public modules: {[s: string]: any};
}

export default class Module {
  protected element: ModuleHTMLElement;
  protected selector: string;
  protected options: {[s: string]: any};

  constructor(element, selector, options) {
    if (this.constructor === Module) {
      throw new TypeError('Abstract class "Module" cannot be instantiated directly.');
    }

    this.element = element as ModuleHTMLElement;
    this.selector = selector;
    this.options = Object.assign({ debug: false }, options);

    this._debug(`Initialized ${this.constructor.name} with ${selector}`);

    // Make instance available via DOM node
    this.element.modules = this.element.modules || {};
    this.element.modules[this.constructor.name] = this;

    this.setup();
  }

  _debug(...args) {
    if (this.options.debug) {
      console.debug(...args);
    }
  }

  setup() {
    throw new Error(`${this.constructor.name}#setup needs to be implemented.`);
  }

  destroy() {
    throw new Error(`${this.constructor.name}#destroy needs to be implemented.`);
  }
}
