import Module from '../lib/module';
import { DomEventApi } from '../lib/dom-events';

export default class OnpageAnchor extends Module {
  private eventApi;
  private target: HTMLElement | null;

  setup() {
    this.target = null;

    this.bind();
  }

  bind() {
    DomEventApi.on(this.element, 'click', '[data-action="scroll-to-href"]', event => {
      const eventTarget = event.target.closest('[data-action="scroll-to-href"]');
      const selector = eventTarget.getAttribute('href');
      this.target = document.querySelector(selector);

      if (this.target) {
        this.scrollToTarget(() => {
          this.target.setAttribute('tabindex', '-1');
          this.target.focus();
          this.target.removeAttribute('tabindex');
          window.location.hash = selector;
        });

        event.preventDefault();
        return false;
      }
    });
  }

  unbind() {
    DomEventApi.off(this.element, 'click', '[data-action="scroll-to-href"]');
  }

  scrollToTarget(callback) {
    this._debug('OnpageAnchor#scrollTo');

    const scrollHandler = () => {
      const isTargetReached: boolean = Math.round(this.target.getBoundingClientRect().top) <= 10;
      const isWindowBottomReached: boolean = document.body.offsetHeight - (window.innerHeight + window.pageYOffset) <= 10;
      const isWindowTopReached: boolean = window.pageYOffset <= 10;

      if (isTargetReached || isWindowBottomReached || isWindowTopReached) {
        DomEventApi.off(window, 'scroll', scrollHandler);
        callback();
      }
    }

    DomEventApi.on(window, 'scroll', scrollHandler);

    this.target.scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    });
  }

  destroy() {
    this.unbind();
  }
}
