import Rails from '@rails/ujs';

import ModuleLoader from './lib/module-loader';
import CollapsibleNavigation from './modules/collapsible-navigation';
import OnpageAnchor from './modules/onpage-anchor';

Rails.start();

const isDevelopment = process.env.NODE_ENV === 'development';
const loader: ModuleLoader = new ModuleLoader({ debug: isDevelopment });

loader.register('collapsible-navigation', CollapsibleNavigation);
loader.register('onpage-anchor', OnpageAnchor);

loader.run();
