export default class ModuleLoader {
  private options: any;
  private didRunAtLeastOnce: boolean;
  private registry: any;

  constructor(options) {
    this.options = Object.assign({ debug: false }, options || {});
    this.didRunAtLeastOnce = false;
    this.registry = {};
  }

  register(name, klass, options?) {
    if (this.options.debug) { console.debug('ModuleLoader#register', name); }

    if (this.registry[name]) {
      throw new Error(`Module with name '${name}' has already been registered.`);
    }

    const entry = {
      klass: klass,
      options: options || {},
      bindings: [],
    };

    this.registry[name] = entry;
  }

  deregister(name) {
    if (this.options.debug) { console.debug('ModuleLoader#deregister', name); }

    const entry = this.registry[name];

    entry.bindings.forEach(binding => {
      binding.module.destroy();
    });

    delete this.registry[name];
  }

  reset() {
    if (this.options.debug) { console.debug('ModuleLoader#reset'); }

    Object.keys(this.registry).forEach(name => {
      const entry = this.registry[name];

      entry.bindings.forEach(binding => {
        binding.module.destroy();
      });
    });
  }

  run() {
    if (this.options.debug) { console.debug('ModuleLoader#run'); }

    Object.keys(this.registry).forEach(name => {
      const entry = this.registry[name];
      const selector = `[data-module*="${name}"]`;

      if (this.didRunAtLeastOnce) {
        // Only keep bindings when element still is in DOM
        entry.bindings = this._clearEntryBindings(entry.bindings);
      }

      Array.from(document.querySelectorAll(selector)).forEach(element => {
        // Skip if element is already bound
        if (entry.bindings.find(binding => binding.element === element)) {
          return;
        }

        entry.bindings.push({
          element: element,
          module: new entry.klass(element, selector, entry.options),
        });
      });
    });

    this.didRunAtLeastOnce = true;
  }

  _clearEntryBindings(bindings) {
    return bindings.filter(binding => {
      return document.body.contains(binding.element);
    });
  }
}
